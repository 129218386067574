import { Box } from "@mui/material";
import styled from "styled-components";

export const MenuBarStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 6px;
  height: 100%;
  .MuiListItemButton-root {
    padding: 12.4px 18px;
    gap: 12px;
    align-items: center;
    flex-grow: 0;
    height: 48.8px;
    .MuiListItemIcon-root {
      min-width: auto;
    }
    .MuiListItemText-root {
      margin: 0;
      .MuiTypography-root {
        color: #f2f4f7;
        font-size: 16px;
        font-weight: 600;
      }
    }
    &:hover,
    &.active {
      background-color: #262e65;
      box-shadow: inset 5px 0 0 0 #6c72ff;
      .MuiListItemIcon-root {
        svg {
          display: block;
          path {
            stroke: #fff;
          }
        }
      }
      .MuiListItemText-root {
        .MuiTypography-root {
          color: #fff;
        }
      }
    }
    &.user-info-block-wrapper {
      opacity: 1 !important;
      cursor: default !important;
      pointer-events: all !important;
      background-color: transparent !important;
      box-shadow: none !important;
    }
  }
  .mt-auto {
    margin-top: auto !important;
  }
`;

export const UserInfoBlockStyled = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  .user-avatar {
    img {
      width: 40px;
      height: auto;
      display: block;
      overflow: auto;
    }
  }
  .user-info {
    .user-name {
      font-weight: 600;
      font-size: 14px;
      color: #fff;
    }
    .user-email {
      font-weight: 400;
      font-size: 14px;
      color: #fff;
    }
  }
  .user-signout {
    margin-left: auto;
    span {
      cursor: pointer;
      svg {
        display: block;
      }
    }
  }
`;
