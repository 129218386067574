import { Box, CircularProgress, TableCell } from "@mui/material";
import { TableLoaderProps } from "./types";
import { TableLoaderStyled } from "./styles";

const TableLoader: React.FC<TableLoaderProps> = ({ columns }) => {
  return (
    <TableLoaderStyled>
      <TableCell colSpan={columns.length}>
        <Box className="loader-wrapper">
          <CircularProgress />
        </Box>
      </TableCell>
    </TableLoaderStyled>
  );
};

export default TableLoader;
