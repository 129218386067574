import Layout from "../components/elements/Layout";
import DataCenterPartials from "../components/partials/DataCenter";

const DataCenter: React.FC = () => {
  return (
    <Layout page="Data Center">
      <DataCenterPartials />
    </Layout>
  );
};

export default DataCenter;
