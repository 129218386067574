import { Box } from "@mui/material";
import styled from "styled-components";

export const TableComponentStyled = styled(Box)`
  .MuiTable-root {
    table-layout: fixed;
    .MuiTableHead-root {
      .MuiTableRow-root {
        .MuiTableCell-root {
          background-color: #12192f;
          padding: 10px 28px;
          line-height: normal;
          word-wrap: break-word;
          height: auto;
          font-weight: 500;
          font-size: 12px;
          color: #d9d9d9;
          border-left: 1px solid #30374d;
          border-right: 1px solid #30374d;
          border-bottom: none;
          &:first-child {
            border-top-left-radius: 12px;
            border-left-color: transparent;
          }
          &:last-child {
            border-top-right-radius: 12px;
            border-right-color: transparent;
          }
        }
      }
    }
    .MuiTableBody-root {
      position: relative;
      .MuiTableRow-root {
        .MuiTableCell-root {
          line-height: normal;
          height: auto;
          border: 1px solid #444b61;
          font-weight: 600;
          font-size: 14px;
          padding: 15px 20px;
          .actions {
            display: inline-flex;
            align-items: center;
            gap: 14px;
            svg {
              display: inline-block;
            }
          }
        }
        &:first-child {
          .MuiTableCell-root {
            border-top: none;
          }
        }
      }
    }
    .MuiTableFooter-root {
      .MuiTableRow-root {
        .MuiTableCell-root {
          padding: 37px 0 0 0;
          border: none;
        }
      }
    }
  }
`;
