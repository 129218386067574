import {
    createBrowserRouter,
} from "react-router-dom";
import Companies from "./pages/Companies";
import Practices from "./pages/Practices";
import Practice from "./pages/Practice";
import Sites from "./pages/Sites";
import Site from "./pages/Site";
import Home from "./pages/Home";
import AgentVersions from "./pages/AgentVersions";
import DataCenter from "./pages/DataCenter";
import DataCenterListing from "./pages/DataCenterListing";

const router = createBrowserRouter([
  {
    path: "/companies",
    Component: Companies,
  },
  {
    path: "/practices",
    Component: Practices,
  },
  {
    path: "/practices/:id",
    Component: Practice,
  },
  {
    path: "/sites",
    Component: Sites,
  },
  {
    path: "/sites/:id",
    Component: Site,
  },
  {
    path: "/data-center",
    Component: DataCenter,
  },
  {
    path: "/data-center-listing",
    Component: DataCenterListing,
  },
  {
    path: "/agent-versions",
    Component: AgentVersions,
  },
  {
    path: "/about",
    element: <div>About</div>,
  },
  {
    path: "*",
        Component: Home
    }
]);


export default router