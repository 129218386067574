import { Controller } from "react-hook-form";
import TextInputField from "./field";
import { AdditionalFormFieldProps, TextFieldProps } from "../common-types";

export const TextInputFormField: React.FC<
  TextFieldProps & AdditionalFormFieldProps
> = (props) => {
  const { name, defaultValue, control } = props;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextInputField
          {...props}
          value={value || ""}
          onChange={onChange}
          error={error}
        />
      )}
    />
  );
};

export default TextInputFormField;
