import { useState } from "react"
import ResponsiveDrawer from "../../elements/Drawer"
import { Button } from "@mui/material"
import { useQuery } from "@apollo/client"
import apiClient, { ApiClient } from "../../../../lib/apiClient"
import PracticeProvisioningForm from "./PracticeProvisioningForm"
import SiteProvisioningForm from "./SiteProvisioningForm"

interface Props {
    entity: {
        id: number | undefined
    },
    entityType: "site" | "practice",
    onCancel?: Function
}
const ProvisioningPartial = (props: Props)=>{
    const [state, setState] = useState({
        drawerOpen: false
    })
    const setDrawer = (value: boolean)=>{
        setState({
            ...state,
            drawerOpen: value
        })
    }

    const { loading, error, data, refetch} = useQuery<ApiClient.GetServers>(apiClient.queries.GET_SERVERS);


    const handleCancel = ()=>{
        setState({
            drawerOpen: false
        })
        props?.onCancel?.()
    }

    return <>
        <Button onClick={setState.bind(null, {drawerOpen: true})} variant="contained">
            Provision
        </Button>
        <ResponsiveDrawer
            anchor='right'
            open={state.drawerOpen}
            onClose={setDrawer.bind(null, false)}
        >
            {props.entityType == "practice" ? (
                <PracticeProvisioningForm practice={props.entity} onUpdate={handleCancel}/>
            ):(
                <SiteProvisioningForm site={props.entity} onUpdate={handleCancel}/>
            )}
        </ResponsiveDrawer>
    </>
}

export default ProvisioningPartial