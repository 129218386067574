import { Box } from "@mui/material";
import styled from "styled-components";

export const SelectFieldStyled = styled(Box)`
  .field-label {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 6px;
  }
  .MuiInputBase-root {
    height: 48px;
    padding: 12px 0;
    background-color: #12192f;
    .MuiSelect-select {
      font-weight: 600;
      font-size: 16px;
      color: #f2f4f7;
    }
    .MuiOutlinedInput-notchedOutline {
      border-radius: 12px !important;
      border-color: #444b61;
    }
  }
`;
