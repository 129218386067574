import Layout from "../components/elements/Layout";
import DataCenterListingPartials from "../components/partials/DataCenterListing";

const DataCenterListing = () => {
  return (
    <Layout page="Data Center Listing">
      <DataCenterListingPartials />
    </Layout>
  );
};
export default DataCenterListing;
