import { Box } from "@mui/material";
import styled from "styled-components";
import searchIcon from "../../../../assets/icons/search.svg";

export const DataCenterPartialsStyled = styled(Box)`
  * {
    box-sizing: border-box !important;
  }
  .heading-container {
    text-align: left;
    .main-heading {
      font-weight: 700;
      font-size: 32px;
      color: #fff;
      margin-bottom: 7px;
      line-height: normal;
    }
    .sub-heading {
      font-weight: 500;
      font-size: 14px;
      color: #d9d9d9;
      line-height: normal;
    }
  }
  .divider {
    border-bottom: 1px solid #444b61 !important;
    margin-top: 16px !important;
  }
  .content-container {
    padding: 50px 0;
    text-align: left;
    display: flex;
    flex-direction: row;
    gap: 32px;
    .list {
      flex-basis: calc(100% / 3);
      border-right: 1px solid #444b61;
      padding-right: 28px;
      /* max-height: calc(100vh - 300px);
      overflow-y: auto; */
      .list-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-weight: 600;
          font-size: 18px;
          color: #f2f4f7;
        }
        .sub-title {
          font-weight: 500;
          font-size: 12px;
          color: #d9d9d9;
        }
      }
      .search-field-container {
        margin: 16px 0;
        .search-field {
          height: 38px;
          width: 100%;
          background-color: #354061;
          border-radius: 12px;
          box-shadow: 0px 1px 2px 0px #1018280d;
          border: none;
          background-image: url(${searchIcon});
          background-repeat: no-repeat;
          background-position-y: center;
          background-position-x: 14px;
          font-weight: 400;
          font-size: 14px;
          color: #fff;
          outline: none;
          padding: 7px 14px 7px 40px;
          &::placeholder {
            color: inherit;
          }
        }
      }
      .list-items-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-height: calc(100vh - 500px);
        overflow-y: auto;
        padding-right: 10px;
        .list-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          .item-label {
            font-weight: 600;
            font-size: 16px;
            color: #f2f4f7;
          }
          .radio-button {
            display: inline-block;
            width: 18px;
            height: 18px;
            border: 1.5px solid #d0d5dd;
            border-radius: 50%;
            &.active {
              border: 5px solid #6c72ff;
            }
          }
        }
      }
    }
  }
  .bottom-section {
    text-align: right;
    margin-top: 6px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .MuiButtonBase-root {
      text-transform: none;
      height: 40px;
      background-color: #6c72ff;
      outline: none;
      width: 270px;
      border-radius: 12px;
      color: #fff;
      font-weight: 700;
      font-size: 16px;
      padding: 8px 50px;
    }
  }
`;
