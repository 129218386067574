import { Box, Typography } from "@mui/material";
import { DataCenterListProps } from "./types";

const DataCenterList: React.FC<DataCenterListProps> = ({
  title,
  subTitle,
  listItems,
  onSelect,
  selectedListItem,
}) => {
  const handleClick = (
    listItemValue: number,
    listItemLabel: string,
    privateKey?: string
  ) => {
    onSelect && onSelect(listItemValue, listItemLabel, privateKey);
  };

  return (
    <Box className="list">
      <Box className="list-header">
        <Typography className="title">{title}</Typography>
        <Typography className="sub-title">{subTitle}</Typography>
      </Box>
      <Box className="search-field-container">
        <input className="search-field" placeholder="Search" type="text" />
      </Box>
      <Box className="list-items-container">
        {listItems.map((listItem, key) => (
          <Box
            key={`list-item-${key}`}
            className="list-item"
            onClick={() =>
              handleClick(listItem.value, listItem.label, listItem.privateKey)
            }
          >
            <Typography className="item-label">{listItem.label}</Typography>
            <span
              className={`radio-button ${
                selectedListItem === listItem.value ? "active" : ""
              }`}
            ></span>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default DataCenterList;
