import { useLocation, useNavigate } from "react-router-dom"
import useSession from "../../../../helpers/useSessionState"
import { Box, Divider, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material"
import { ReactComponent as DashboardIcon } from "../../../../assets/icons/dashboard.svg"
import { ReactComponent as AgentVersionsIcon } from "../../../../assets/icons/agent-versions.svg"
import { ReactComponent as CompaniesIcon } from "../../../../assets/icons/companies.svg"
import { ReactComponent as PracticesIcon } from "../../../../assets/icons/practices.svg"
import { ReactComponent as SitesIcon } from "../../../../assets/icons/sites.svg"
import { ReactComponent as DatabaseIcon } from "../../../../assets/icons/database.svg"
import { ReactComponent as NotificationsIcon } from "../../../../assets/icons/notifications.svg"
import { ReactComponent as SupportIcon } from "../../../../assets/icons/support.svg"
import { ReactComponent as SettingsIcon } from "../../../../assets/icons/settings.svg"
import { ReactComponent as SignoutIcon } from "../../../../assets/icons/signout.svg"

import LogoutIcon from '@mui/icons-material/Logout';
import useBreakpoints from "../../../../helpers/useMedia";
import { MenuBarStyled, UserInfoBlockStyled } from "./MenuBar.styles";

import avatar from "../../../../assets/images/Avatar.png";
import { useEffect, useState } from "react"

interface Props{
  onNavigate?: Function
  onClose?: Function
}

interface Session {
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone?: string;
  token: string;
  isSuper: boolean;
  __typename: string;
}

const UserInfoBlock: React.FC<{ handleLogout: () => void }> = ({
  handleLogout,
}) => {
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    const sessionData = localStorage.getItem('session');
    if (sessionData) {
      try {
        const parsedSession: Session = JSON.parse(sessionData);
        setSession(parsedSession);
      } catch (error) {
        console.error('Error parsing session data:', error);
      }
    }
  }, []);
  return (
    <UserInfoBlockStyled>
      <Box className="user-avatar">
        <img src={avatar} alt="User Avatar" />
      </Box>
      <Box className="user-info">
        <Typography className="user-name">{session?.firstName + ' ' + session?.lastName}</Typography>
        <Typography className="user-email">{session?.email}</Typography>
      </Box>
      <Box className="user-signout">
        <span onClick={handleLogout}>
          <SignoutIcon />
        </span>
      </Box>
    </UserInfoBlockStyled>
  );
};

const MenuBar = (props: Props)=>{
  const navigate = useNavigate()
  const location = useLocation()
  const {isLg} = useBreakpoints()
  const [sessionState, setSession] = useSession()
// <Link to='/practices'>Practices</Link>
// <Link to='/sites'>Sites</Link>
// <Link to='/calendar'>Calendar</Link>
  const navigateTo = (target: string)=>{
    navigate(target)
    props?.onNavigate?.()
  }

  const handleClose = ()=>{
    props?.onClose?.()
  }

const handleLogout = () => {
    setSession(undefined)
};

const isActive = (path: string | string[]) => {
  if (typeof path === 'string') {
    return location.pathname === path;
  } else {
    return path.includes(location.pathname);
  }
};

  return (
    <MenuBarStyled>
      <ListItemButton className={isActive('/') ? 'active' : ''} onClick={navigateTo.bind(null, '/')}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      {sessionState?.isSuper && (
        <>
          <ListItemButton className={isActive('/agent-versions') ? 'active' : ''} onClick={navigateTo.bind(null, '/agent-versions')}>
            <ListItemIcon>
              <AgentVersionsIcon />
            </ListItemIcon>
            <ListItemText primary="Agent Versions" />
          </ListItemButton>
          <ListItemButton className={isActive('/companies') ? 'active' : ''} onClick={navigateTo.bind(null, '/companies')}>
            <ListItemIcon>
              <CompaniesIcon />
            </ListItemIcon>
            <ListItemText primary="Companies" />
          </ListItemButton>
        </>
      )}
      <ListItemButton className={isActive('/practices') ? 'active' : ''} onClick={navigateTo.bind(null, '/practices')}>
        <ListItemIcon>
          <PracticesIcon />
        </ListItemIcon>
        <ListItemText primary="Practices" />
      </ListItemButton>
      <ListItemButton className={isActive('/sites') ? 'active' : ''} onClick={navigateTo.bind(null, '/sites')}>
        <ListItemIcon>
          <SitesIcon />
        </ListItemIcon>
        <ListItemText primary="Sites" />
      </ListItemButton>
      <ListItemButton className={isActive(['/data-center', '/data-center-listing']) ? 'active' : ''} onClick={navigateTo.bind(null, "/data-center")}>
        <ListItemIcon>
          <DatabaseIcon />
        </ListItemIcon>
        <ListItemText primary="Data Center" />
      </ListItemButton>

      <ListItemButton
        className={(isActive('/notifications') ? 'active' : '') + ' mt-auto'}
        onClick={navigateTo.bind(null, "/notifications")}
      >
        <ListItemIcon>
          <NotificationsIcon />
        </ListItemIcon>
        <ListItemText primary="Notifications" />
      </ListItemButton>
      <ListItemButton className={isActive('/support') ? 'active' : ''} onClick={navigateTo.bind(null, "/support")}>
        <ListItemIcon>
          <SupportIcon />
        </ListItemIcon>
        <ListItemText primary="Support" />
      </ListItemButton>
      <ListItemButton className={isActive('/settings') ? 'active' : ''} onClick={navigateTo.bind(null, "/settings")}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItemButton>

      <ListItemButton
        disabled
        sx={{ paddingRight: "0 !important", opacity: "1 !important" }}
      >
        <ListItemText
          primary={<Divider sx={{ borderBottom: "1px solid #475467" }} />}
        />
      </ListItemButton>
      <ListItemButton
        disabled
        className="user-info-block-wrapper"
        sx={{ height: "auto !important", opacity: "1 !important" }}
      >
        <UserInfoBlock handleLogout={handleLogout} />
      </ListItemButton>

      {!isLg && (
        <>
          <ListItemButton className={isActive('/practices') ? 'active' : ''} onClick={navigateTo.bind(null, '/practices')}>
            <ListItemIcon>
              <PracticesIcon />
            </ListItemIcon>
            <ListItemText primary="settings" />
          </ListItemButton>
          <ListItemButton onClick={handleClose} style={{ textAlign: "center" }}>
            <ListItemText primary="Cancel" />
          </ListItemButton>
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </>)}
    </MenuBarStyled>
  )
}

export default MenuBar