import { useQuery } from "@apollo/client";
import useSession from "../../../../helpers/useSessionState";
import apiClient, { ApiClient } from "../../../../lib/apiClient";
import * as React from "react";
import {
  styled,
  createTheme,
  ThemeProvider,
  useTheme,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import MenuBar from "./MenuBar";
import logo from "./logo.png";
import DropdownMenu from "./DropdownMenu";
import { useNavigate } from "react-router-dom";
import CompanyDropdown from "../CompanyDropdown";
import PracticeDropdown from "../PracticeDropdown";
import useBreakpoints from "../../../../helpers/useMedia";
import MobileMenu from "./MobileMenu";
import { IndexStyled } from "./Index.styles";

// import { mainListItems, secondaryListItems } from './listItems';
// import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Next In Line
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
  palette: {
    mode: 'dark', // Ensures the theme is in dark mode
    primary: {
      main: '#080f25', // Your base color
      contrastText: '#ffffff', // A good contrast for dark primary colors
    },
    background: {
      default: '#0a1128', // Slightly lighter variant for the background
      paper: '#080f25', // Paper elements
    },
    text: {
      primary: '#ffffff', // White text for readability
      secondary: '#c5c9e0', // Subtle contrast for secondary text
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#6b6b6b #2b2b2b",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#2b2b2b",
            width: 2,
          },

          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#6b6b6b",
            minHeight: 24,
            border: "3px solid #2b2b2b",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#2b2b2b",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          '&:hover': {
            backgroundColor: '#101830', // Slightly lighter shade for hover
          },
          '&:active': {
            backgroundColor: '#1a2b4a', // Even lighter for active
          },
        },
        textPrimary: {
          color: 'white', // Override textPrimary color
        },
        text: {
          color: 'white', // General text button color
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: 'inherit', // Ensures it inherits `text.primary` or `text.secondary`
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#ffffff', // Ensure links are white or use `palette.text.primary`
          textDecoration: 'none', // Remove underline if not needed
          '&:hover': {
            textDecoration: 'underline', // Add underline on hover for clarity
            color: '#c5c9e0', // Optional hover color for links
          },
        },
        underlineAlways: {
          textDecoration: 'underline', // Ensure always underlined
        },
      },
    },
  },
});

const Layout = ({
  children,
  page,
}: React.PropsWithChildren & { page: string }) => {
  const [sessionState] = useSession();
  const navigate = useNavigate();
  const theme = useTheme();

  const { isSm, isMd, isLg } = useBreakpoints();
  const { data } = useQuery<ApiClient.GetInitialData>(
    apiClient.queries.GET_CURRENT_USER_DATA
  );

  const company = data?.companies.find(
    (company) => company.id === sessionState?.currentCompanyId
  );

  const practice = company?.practices?.find(
    (practice) => practice.id === sessionState?.currentPracticeId
  );
  const site = practice?.sites?.find(
    (site) => site.id === sessionState?.currentSiteId
  );
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <IndexStyled>
        <CssBaseline />
        <Drawer
          PaperProps={{
            sx: {
              backgroundColor: "transparent",
              padding: "0",
              border: "none",
              width: "100% !important",
            },
          }}
          sx={{ width: "330px", maxHeight: "100vh" }}
          variant={isLg ? "permanent" : "temporary"}
          open={true}
        >
          <Box className="logo-and-search-container">
            <Box className="logo-container">
              <img
                src={logo}
                alt="Logo"
                className="logo"
              />
            </Box>
            <Box className="sidebar-search-field-container">
              <input
                className="sidebar-search-field"
                type="text"
                placeholder="Search"
              />
            </Box>
          </Box>
          <List
            component="nav"
            className="menu-bar"
          >
            <MenuBar />
          </List>
        </Drawer>
        <Box
          component="main"
          className="main-content"
        >
          <Container
            className="content-container"
          >
            <Grid container spacing={0}>
              {/* Chart */}
              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    minHeight: "100%",
                    padding: "28px 32px",
                    borderTopLeftRadius: "30px",
                    borderBottomLeftRadius: "30px",
                    backgroundColor: "#080F25",
                  }}
                  style={{ position: "relative" }}
                  elevation={0}
                >
                  {children}
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </IndexStyled>
      {!isLg && <MobileMenu open={open} onClose={setOpen.bind(null, false)} />}
    </ThemeProvider>
  );
};

export default Layout;
