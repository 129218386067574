import { TableRow } from "@mui/material";
import styled from "styled-components";

export const TableLoaderStyled = styled(TableRow)`
  .MuiTableCell-root {
    padding: 0;
    margin: 0;
    height: 0;
    .loader-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
