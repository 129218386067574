import { Box } from "@mui/material"
import styled from "styled-components"

export const TextFieldStyle = styled(Box)`
  .field-label {
    color: var(--slate-blue-color);
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 6px;
  }

  .MuiFormControl-root {
    .MuiInputBase-root {
      padding: 0 14px 0 0 !important;
      .MuiInputBase-input {
        height: 44px;
        padding: 10px 14px;
        font-size: 16px;
        font-weight: 400;
        &::placeholder {
          color: var(--cool-gray-color);
        }
        &:focus {
          ~ .MuiOutlinedInput-notchedOutline {
            border: 1px solid var(--royal-blue-color);
            box-shadow: 1px 2px 5px 0 rgba(0,0,0,0.2);
          }
        }
      }
      .MuiOutlinedInput-notchedOutline {
        transition: 0.1s linear all;
        border: 1px solid var(--light-blue-gray-color);
        border-radius: 16px;
      }
    }
  }
`
