export const columns: object = {
  clients: [
    {
      id: "id",
      label: "ID",
    },
    {
      id: "firstName",
      label: "First Name",
    },
    {
      id: "lastName",
      label: "Last Name",
    },
    {
      id: "balance",
      label: "Balance",
    },
    {
      id: "isActive",
      label: "Is Active",
    },
    {
      id: "actions",
      label: "",
    },
  ],
  patients: [
    {
      id: "id",
      label: "ID",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "birthdate",
      label: "Birth Date",
    },
    {
      id: "weight",
      label: "Weight",
    },
    {
      id: "microchip",
      label: "Microchip",
    },
    {
      id: "allergies",
      label: "Allergies",
    },
    {
      id: "isDeceased",
      label: "Is Deceased",
    },
    {
      id: "isActive",
      label: "Is Active",
    },
    {
      id: "updatedAt",
      label: "Updated At",
    },
    {
      id: "actions",
      label: "",
    },
  ],
  appointments: [
    {
      id: "id",
      label: "ID",
    },
    {
      id: "startsAt",
      label: "Starts At",
    },
    {
      id: "endsAt",
      label: "Ends At",
    },
    {
      id: "covetrusId",
      label: "Covetrus ID",
    },
    {
      id: "integration",
      label: "Integration",
    },
    {
      id: "isActive",
      label: "Is Active",
    },
    {
      id: "isDeleted",
      label: "Is Deleted",
    },
    {
      id: "actions",
      label: "",
    },
  ],
  appointmentsTypes: [
    {
      id: "id",
      label: "ID",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "covetrusId",
      label: "Covetrus ID",
    },
    {
      id: "integration",
      label: "Integration",
    },
    {
      id: "isActive",
      label: "Is Active",
    },
    {
      id: "isDeleted",
      label: "Is Deleted",
    },
    {
      id: "actions",
      label: "",
    },
  ],
  appointmentsStatuses: [
    {
      id: "id",
      label: "ID",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "covetrusId",
      label: "Covetrus ID",
    },
    {
      id: "integration",
      label: "Integration",
    },
    {
      id: "isActive",
      label: "Is Active",
    },
    {
      id: "isDeleted",
      label: "Is Deleted",
    },
    {
      id: "actions",
      label: "",
    },
  ],
  breeds: [
    {
      id: "id",
      label: "ID",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "covetrusId",
      label: "Covetrus ID",
    },
    {
      id: "integration",
      label: "Integration",
    },
    {
      id: "isActive",
      label: "Is Active",
    },
    {
      id: "isDeleted",
      label: "Is Deleted",
    },
    {
      id: "actions",
      label: "",
    },
  ],
  species: [
    {
      id: "id",
      label: "ID",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "covetrusId",
      label: "Covetrus ID",
    },
    {
      id: "integration",
      label: "Integration",
    },
    {
      id: "isActive",
      label: "Is Active",
    },
    {
      id: "isDeleted",
      label: "Is Deleted",
    },
    {
      id: "actions",
      label: "",
    },
  ],
  sexes: [
    {
      id: "id",
      label: "ID",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "covetrusId",
      label: "Covetrus ID",
    },
    {
      id: "integration",
      label: "Integration",
    },
    {
      id: "isActive",
      label: "Is Active",
    },
    {
      id: "isDeleted",
      label: "Is Deleted",
    },
    {
      id: "actions",
      label: "",
    },
  ],
  invoices: [
    {
      id: "id",
      label: "ID",
    },
    {
      id: "covetrusId",
      label: "Covetrus ID",
    },
    {
      id: "integration",
      label: "Integration",
    },
    {
      id: "isActive",
      label: "Is Active",
    },
    {
      id: "isDeleted",
      label: "Is Deleted",
    },
    {
      id: "actions",
      label: "",
    },
  ],
};

export const resources = [
  {
    label: "Clients",
    value: "clients",
  },
  {
    label: "Patients",
    value: "patients",
  },
  {
    label: "Appointments",
    value: "appointments",
  },
  {
    label: "Appointments Types",
    value: "appointmentsTypes",
  },
  {
    label: "Appointments Statuses",
    value: "appointmentsStatuses",
  },
  {
    label: "Breeds",
    value: "breeds",
  },
  {
    label: "Species",
    value: "species",
  },
  {
    label: "Sexes",
    value: "sexes",
  },
  {
    label: "Invoices",
    value: "invoices",
  },
];
