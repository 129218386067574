import { Box } from "@mui/material";
import styled from "styled-components";

export const ModalComponentStyled = styled.div`
`

export const BoxStyled = styled(Box) <{ width?: string }>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${p => (p.width || '1018px')};
    background: #212C4D;
    box-shadow: 0px 0px 4px rgba(240, 240, 240, 0.4);
    border-radius: 10px;
    padding: 16px 17px 24px 16px;
    outline: none;
    .title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        #modal-modal-title {
            font-weight: 700;
            font-size: 28px;
            
        }
        .MuiTypography-root {
            color: #FFFFFF;
        }
        #modal-modal-sub-title {
            font-weight: 500;
            font-size: 14px;
        }
        .close-icon {
            width: 20px;
            height: 20px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .MuiSvgIcon-root {
                color: #70767B;
                display: block;
                width: 20px;
                height: 20px;
            }
        }
    }
    .content-container-modal {
        padding-top: 20px;
    }
`