import React, { useState } from "react";
import "./App.css";
import Authenticate from "./Authenticate";
import apiClient from "./lib/apiClient";
import { ApolloProvider } from "@apollo/client";
import { RouterProvider } from "react-router-dom";
import router from "./app/Router";
import useSession from "./helpers/useSessionState";
import { DataCenterContext } from "./app/components/partials/DataCenter/context";

let params = new URLSearchParams(document.location.search);

type LabelValue = {
  label: string;
  value: number;
};

function App() {
  const [sessionState, setSessionState] = useSession();
  const [selectedCompany, setSelectedCompany] = useState<
    LabelValue | undefined
  >();
  const [selectedPractice, setSelectedPractice] = useState<
    LabelValue | undefined
  >();
  const [selectedSite, setSelectedSite] = useState<LabelValue | undefined>();

  let token: string | null = "";
  let authenticating = false;
  if (window.location.pathname === "/session/authentication") {
    token = params.get("token");
    if (token) {
      if (!sessionState?.email) {
        authenticating = true;
        apiClient.authenticate(token).then((response) => {
          setSessionState(response.data.authenticate);
        });
      } else {
        window.location.href = "/";
      }
    }
  }

  return (
    <ApolloProvider client={apiClient.connection}>
      <DataCenterContext.Provider
        value={{
          selectedCompany,
          selectedPractice,
          selectedSite,
          setSelectedCompany,
          setSelectedPractice,
          setSelectedSite,
        }}
      >
        <div className="App">
          {!sessionState?.email ? (
            authenticating ? (
              <div>Loading</div>
            ) : (
              <Authenticate />
            )
          ) : (
            <RouterProvider router={router} />
          )}
        </div>
      </DataCenterContext.Provider>
    </ApolloProvider>
  );
}

export default App;
