import { createContext } from "react";

type LabelValue = {
  label: string;
  value: number;
};

export const DataCenterContext = createContext<{
  selectedCompany?: LabelValue;
  selectedPractice?: LabelValue;
  selectedSite?: LabelValue;
  setSelectedCompany: (selectedCompany: LabelValue) => void;
  setSelectedPractice: (selectedPractice: LabelValue) => void;
  setSelectedSite: (selectedSite: LabelValue) => void;
}>({
  selectedCompany: undefined,
  selectedPractice: undefined,
  selectedSite: undefined,
  setSelectedCompany: (selectedCompany: LabelValue) => {},
  setSelectedPractice: (selectedPractice: LabelValue) => {},
  setSelectedSite: (selectedSite: LabelValue) => {},
});
