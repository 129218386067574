import { useQuery } from "@apollo/client";
import apiClient, { ApiClient } from "../../../../lib/apiClient";
import useSession from "../../../../helpers/useSessionState";
import { Link, Button, Drawer, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import Layout from "../../elements/Layout";
import CreatePractice from "./PracticeCreate";
import ResponsiveDrawer from "../../elements/Drawer";

const PracticesPartial = ()=>{
    const [state, setState] = useState({
        drawerOpen: false
    })

    const [sessionState, setSession] = useSession()
    

    const navigate = useNavigate()
    const { loading, error, data } = useQuery<ApiClient.GetPractices>(apiClient.queries.GET_PRACTICES, {
        variables: {
            companyId: sessionState?.currentCompanyId
        }
    });

    const setDrawer = (value: boolean)=>{
        setState({
            ...state,
            drawerOpen: value
        })
    }
    
    const handleSelectPractice = (practice: ApiClient.GetPractices["practices"][0])=>{
        setSession({
            ...Object(sessionState),
            currentPracticeId: practice.id
        })
        navigate("/")
    }

    return <>
        {loading ? (
            <>
                LOADING...
            </>
        ):error?(
            <>{error.message}</>
        ):(
            <>
                {sessionState?.isSuper && sessionState?.currentCompanyId != null && (
                    <div>
                        <ResponsiveDrawer
                            anchor='right'
                            open={state.drawerOpen}
                            onClose={setDrawer.bind(null, false)}
                        >
                            <CreatePractice onComplete={setDrawer.bind(null, false)} onCancel={setDrawer.bind(null, false)} />
                        </ResponsiveDrawer>
                    </div>
                )}
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell align='right'>
                                    {sessionState?.isSuper && sessionState?.currentCompanyId != null && (
                                        <Button variant="contained" onClick={setDrawer.bind(null, true)} size='small'>
                                            <AddIcon />
                                        </Button>
                                    )}
                                </TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.practices.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                     <TableCell component="th" scope="row">
                                       {row.id}
                                     </TableCell>
                                     <TableCell>
                                        <Link href={`/practices/${row.id}`}>{row.name}</Link>
                                    </TableCell>
                                     <TableCell>
                                        <Button onClick={handleSelectPractice.bind(null, row)}>Use</Button>
                                     </TableCell>
                                   </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            </>
        )}
    </>
}

export default PracticesPartial