import { TablePagination } from "@mui/material";
import styled from "styled-components";

export const TablePaginationStyled = styled(TablePagination)`
  .MuiToolbar-root {
    padding: 0;
    min-height: auto;
    max-height: 100%;
    height: 40px;
    .MuiTablePagination-spacer,
    .MuiTablePagination-displayedRows {
      display: none;
    }
    .table-pagination-pages-controls {
      display: flex;
      height: inherit;
      align-items: center;
      flex-grow: 1;
      .MuiButtonBase-root {
        height: inherit;
      }
      .prev-btn,
      .next-btn {
        display: flex;
        align-items: center;
        gap: 8px;
        .MuiTypography-root {
          font-weight: 600;
          font-size: 14px;
          color: #fff;
        }
        svg {
          display: inline-block;
          width: 20px;
          path {
            stroke: #fff;
          }
        }
        &.Mui-disabled {
          opacity: 40%;
        }
        &.next-btn {
          margin-left: auto;
        }
      }
      .pages {
        margin-left: auto;
        display: inline-flex;
        align-items: center;
        height: inherit;
        flex-grow: 1;
        justify-content: right;
        padding-right: 50px;
        gap: 2px;
        .MuiButtonBase-root {
          min-width: auto;
          max-width: 100%;
          width: 40px;
          height: 40px;
          border-radius: 12px;
          color: #fff;
          font-weight: 500;
          font-size: 14px;
          &.active {
            background-color: #262e65;
            color: #6c72ff;
            font-weight: 600;
            font-size: 16px;
          }
        }
      }
    }
  }
`;
