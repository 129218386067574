import { useMutation, useQuery } from "@apollo/client";
import apiClient, { ApiClient } from "../../lib/apiClient";
import useSession from "../../helpers/useSessionState";
import {
  Button,
  Drawer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { Link, Routes, Route, useParams, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from '@mui/icons-material/Remove';

import Layout from "../components/elements/Layout";
import { DateTime } from "luxon";
import DataDrawer from "../components/elements/DataDrawer";
import AgentVersionUpload from "../components/elements/AgentVersionUpgrade";
// import PracticeUpdate from "../components/partials/Practices/PracticeUpdate";
// import PracticeUpdateDrawer from "../components/partials/Practices/PracticeUpdateDrawer";
import CloseIcon from '@mui/icons-material/Close';
import Provision from "../components/partials/Provisioning";

type State = {
  drawerOpen: boolean;
  site?: ApiClient.GetPractices["practices"][0];
  showPrivateKey: boolean;
};

const Practice = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [state, setState] = useState<State>({
    drawerOpen: false,
    showPrivateKey: false
  });

  const {
    loading,
    error,
    data,
    refetch: practiceRefetch,
  } = useQuery<ApiClient.GetPractice>(apiClient.queries.GET_PRACTICE, {
    variables: {
      id: parseInt(id ?? ""),
    },
  });

  const setDrawer = (value: boolean) => {
    setState({
      ...state,
      drawerOpen: value,
    });
  };

  const handleShowPrivateKey = () => {
    setState({
      showPrivateKey: !state.showPrivateKey,
      drawerOpen: state.drawerOpen
    })
  }

  console.log(data)

  return (
    <Layout page="Practice">
      {loading ? (
        <>LOADING...</>
      ) : error ? (
        <>{error.message}</>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "left",
            }}
          >
            <div>
              <div>Id: {data?.practice?.id}</div>
              <div>Created At: {data?.practice?.createdAt}</div>
              <div>Name: {data?.practice?.name}</div>
              <div>Description: {data?.practice?.description}</div>
              <div>Integration: {data?.practice?.integration}</div>
              <div>Phone Number: {data?.practice?.phoneNumber}</div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
              }}
            >
              {data?.practice?.remoteId ? (
                <div>
                  Practice is provisioned
                  <Button onClick={handleShowPrivateKey}>
                    {state.showPrivateKey ? 'Hide' : 'Show'}
                  </Button>
                  {state.showPrivateKey && data?.practice?.privateKey && data?.practice?.privateKey}
                </div>
              ):(
                <div style={{textAlign: "right"}}>
                  Practice is not currently provisioned
                  <br />
                  <Provision entity={{id: data?.practice?.id}} entityType="practice" />
                </div>
              )}

            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default Practice;
