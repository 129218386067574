import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { DataCenterListingPartialsStyled } from "./styles";
import { ReactComponent as SwitchIcon } from "../../../../assets/icons/switch.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import { ReactComponent as ViewIcon } from "../../../../assets/icons/view.svg";
import TableComponent from "../../elements/TableComponent";
import { useForm } from "react-hook-form";
import SelectFormField from "../../elements/FormFields/SelectField";
import SwitchSiteModal from "../../elements/switch-site-modal/switch-site-modal";
import { useLazyQuery } from "@apollo/client";
import apiClient, { ApiClient } from "../../../../lib/apiClient";
import { columns, resources } from "./constants";
import useDebouncedCallback from "../../../../hooks/useDebouncedCallback";
import TextInputFormField from "../../elements/FormFields/TextInputField";
import { transformData } from "../../../../lib/utils";
import { DataCenterContext } from "../DataCenter/context";
import { Navigate, useLocation } from "react-router-dom";

const rowsPerPage = 5;
export const generateActions = (id: string) => (
  <Box className="actions" key={id}>
    <a href={`/${id}`}>
      <ViewIcon />
    </a>
    <a href={`/${id}`}>
      <EditIcon />
    </a>
  </Box>
);

const DataCenterListingPartials: React.FC = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [totalRecords, setTotalRecords] = useState<number | undefined>(0);
  const { selectedCompany, selectedPractice, selectedSite } =
    useContext(DataCenterContext);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [tableColumns, setTableColumns] = useState<
    { id: string; label: string }[]
  >([]);
  const [dataSource, setDataSource] = useState<any[]>([]);

  const [selectedResource, setSelectedResource] = useState<string>("");

  const { control, watch, setValue } = useForm({
    mode: "all",
    defaultValues: {
      resource: "clients",
      search: "",
    },
  });

  const [getClients, { refetch: refetchClients, called: getClientsCalled }] =
    useLazyQuery<ApiClient.GetDataHubClients>(
      apiClient.queries.GET_DATAHUB_CLIENTS,
      {
        context: { clientName: "dataHubApi" },
      }
    );

  const [getPatients] = useLazyQuery<ApiClient.GetDataHubPatients>(
    apiClient.queries.GET_DATAHUB_PATIENTS,
    {
      context: { clientName: "dataHubApi" },
    }
  );

  const [getAppointmentTypes] =
    useLazyQuery<ApiClient.GetDataHubAppointmentTypes>(
      apiClient.queries.GET_DATAHUB_APPOINTMENT_TYPES,
      {
        context: { clientName: "dataHubApi" },
      }
    );

  const [getAppointmentStatuses] =
    useLazyQuery<ApiClient.GetDataHubAppointmentStatuses>(
      apiClient.queries.GET_DATAHUB_APPOINTMENT_STATUSES,
      {
        context: { clientName: "dataHubApi" },
      }
    );

  const [getBreeds] = useLazyQuery<ApiClient.GetDataHubBreeds>(
    apiClient.queries.GET_DATAHUB_BREEDS,
    {
      context: { clientName: "dataHubApi" },
    }
  );

  const [getSpecies] = useLazyQuery<ApiClient.GetDataHubSpecies>(
    apiClient.queries.GET_DATAHUB_SPECIES,
    {
      context: { clientName: "dataHubApi" },
    }
  );

  const [getSexes] = useLazyQuery<ApiClient.GetDataHubSexes>(
    apiClient.queries.GET_DATAHUB_SEXES,
    {
      context: { clientName: "dataHubApi" },
    }
  );

  const [getInvoices] = useLazyQuery<ApiClient.GetDataHubInvoices>(
    apiClient.queries.GET_DATAHUB_INVOICES,
    {
      context: { clientName: "dataHubApi" },
    }
  );

  const [getAppointments] = useLazyQuery<ApiClient.GetDataHubAppointments>(
    apiClient.queries.GET_DATAHUB_APPOINTMENTS,
    {
      context: { clientName: "dataHubApi" },
    }
  );

  useEffect(() => {
    if (!selectedSite) return;

    const { resource } = watch();
    let page = currentPage;
    if (resource !== selectedResource) {
      // @ts-ignore
      setTableColumns(columns[resource] || []);
      setDataSource([]);
      setCurrentPage(1);
      page = 1;
      setSearch("");
      setValue("search", "");
    }
    const offset = (page - 1) * rowsPerPage;
    if (resource === "clients") {
      getClients({
        variables: {
          search: {
            search,
          },
          pagination: { limit: rowsPerPage, offset },
        },
        onCompleted(data) {
          const { clients } = data;
          setDataSource(transformData(clients?.clients || [], resource));
          setTotalRecords(clients?.total);
        },
      });
    } else if (resource === "patients") {
      getPatients({
        variables: {
          search: {
            search,
          },
          pagination: { limit: rowsPerPage, offset },
        },
        onCompleted(data) {
          const { patients } = data;
          setDataSource(transformData(patients?.patients || [], resource));
          setTotalRecords(patients?.total);
        },
      });
    } else if (resource === "appointmentsTypes") {
      getAppointmentTypes({
        variables: {
          name: search,
          pagination: { limit: rowsPerPage, offset },
        },
        onCompleted(data) {
          const { appointmentTypesByName } = data;
          setDataSource(
            transformData(
              appointmentTypesByName?.appointmentTypes || [],
              resource
            )
          );
          setTotalRecords(appointmentTypesByName?.total);
        },
      });
    } else if (resource === "appointments") {
      getAppointments({
        variables: {
          pagination: { limit: rowsPerPage, offset },
        },
        onCompleted(data) {
          const { appointments } = data;
          setDataSource(
            transformData(appointments?.appointments || [], resource)
          );
          setTotalRecords(appointments?.total);
        },
      });
    } else if (resource === "appointmentsStatuses") {
      getAppointmentStatuses({
        variables: {
          name: search,
          pagination: { limit: rowsPerPage, offset },
        },
        onCompleted(data) {
          const { appointmentStatusByName } = data;
          setDataSource(
            transformData(
              appointmentStatusByName?.appointmentStatus || [],
              resource
            )
          );
          setTotalRecords(appointmentStatusByName?.total);
        },
      });
    } else if (resource === "breeds") {
      getBreeds({
        variables: {
          name: search,
          pagination: { limit: rowsPerPage, offset },
        },
        onCompleted(data) {
          const { breedsByName } = data;
          setDataSource(transformData(breedsByName?.breeds || [], resource));
          setTotalRecords(breedsByName?.total);
        },
      });
    } else if (resource === "species") {
      getSpecies({
        variables: {
          name: search,
          pagination: { limit: rowsPerPage, offset },
        },
        onCompleted(data) {
          const { speciesByName } = data;
          setDataSource(transformData(speciesByName?.species || [], resource));
          setTotalRecords(speciesByName?.total);
        },
      });
    } else if (resource === "sexes") {
      getSexes({
        variables: {
          name: search,
          pagination: { limit: rowsPerPage, offset },
        },
        onCompleted(data) {
          const { sexesByName } = data;
          setDataSource(transformData(sexesByName?.sexes || [], resource));
          setTotalRecords(sexesByName?.total);
        },
      });
    } else if (resource === "invoices") {
      getInvoices({
        variables: {
          name: search,
          pagination: { limit: rowsPerPage, offset },
        },
        onCompleted(data) {
          const { invoicesBySearch } = data;
          setDataSource(
            transformData(invoicesBySearch?.invoices || [], resource)
          );
          setTotalRecords(invoicesBySearch?.total);
        },
      });
    }
    setSelectedResource(resource);
  }, [watch().resource, currentPage, search]); // eslint-disable-line

  useEffect(() => {
    if (getClientsCalled) {
      refetchClients({
        search: {
          search: "",
        },
        pagination: {
          limit: rowsPerPage,
          offset: 0,
        },
      })
        .then((data) => {
          const { clients } = data.data;
          setDataSource(transformData(clients?.clients || [], "clients"));
          setTotalRecords(clients?.total);
        })
        .catch(() => {
          setDataSource([]);
          setTotalRecords(0);
        })
        .finally(() => {
          // @ts-ignore
          setTableColumns(columns["clients"] || []);
          setCurrentPage(1);
          setSearch("");
          setValue("search", "");
          setValue("resource", "clients");
        });
    }
  }, [selectedSite?.value]); // eslint-disable-line

  const handleSearch = useDebouncedCallback((s: string) => {
    setCurrentPage(1);
    setSearch(s.trim());
  });

  useEffect(() => {
    const { search: s } = watch();
    handleSearch(s);
  }, [watch().search]); // eslint-disable-line

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  if (!selectedSite) {
    return <Navigate to="/data-center" state={{ from: location }} replace />;
  }

  return (
    <DataCenterListingPartialsStyled>
      <Box className="heading-container">
        <Typography className="main-heading">
          {selectedSite?.label}
          <span onClick={handleOpen}>
            <SwitchIcon />
          </span>
        </Typography>
        <Typography className="sub-heading">
          {selectedCompany?.label} - {selectedPractice?.label}
        </Typography>
      </Box>
      <Box className="filters-container">
        <SelectFormField
          name="resource"
          control={control}
          placeholder="Resources"
          options={resources}
        />
        <SelectFormField
          name="date"
          control={control}
          placeholder="Date"
          options={[]}
        />
        <SelectFormField
          name="archived"
          control={control}
          placeholder="Archived"
          options={[]}
        />
        <SelectFormField
          name="allColumns"
          control={control}
          placeholder="All Columns"
          options={[]}
        />
      </Box>
      <Box className="search-field-container">
        <TextInputFormField
          className="search-field"
          placeholder="Search"
          name="search"
          control={control}
        />
      </Box>
      <Box className="content-container">
        <TableComponent
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          totalRecords={totalRecords}
          currentPage={currentPage}
          columns={tableColumns}
          data={dataSource}
        />
      </Box>
      {open && <SwitchSiteModal onClose={handleClose} />}
    </DataCenterListingPartialsStyled>
  );
};

export default DataCenterListingPartials;
