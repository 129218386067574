import { Box } from "@mui/material";
import styled from "styled-components";
import searchIcon from "../../../../assets/icons/search.svg";
import arrowDownIcon from "../../../../assets/icons/arrow-down.svg";

export const DataCenterListingPartialsStyled = styled(Box)`
  * {
    box-sizing: border-box !important;
  }
  .heading-container {
    text-align: left;
    .main-heading {
      font-weight: 700;
      font-size: 32px;
      color: #fff;
      margin-bottom: 7px;
      line-height: normal;
      display: flex;
      align-items: center;
      gap: 14px;
      span {
        svg {
          display: block;
        }
      }
    }
    .sub-heading {
      font-weight: 500;
      font-size: 12px;
      color: #d9d9d9;
      line-height: normal;
    }
  }
  .filters-container {
    background-color: #12192f;
    padding: 20px;
    margin: 20px 0 24px 0;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 16px;
    select {
      outline: none;
      background-color: #12192f;
      border: 1px solid #444b61;
      border-radius: 12px;
      color: #f2f4f7;
      font-weight: 600;
      font-size: 18px;
      padding: 12px 40px 12px 12px;
      appearance: none;
      background-image: url(${arrowDownIcon});
      background-repeat: no-repeat;
      background-position-y: center;
      background-position-x: calc(100% - 15px);
    }
  }
  .search-field-container {
    .search-field {
      .MuiFormControl-root {
        .MuiInputBase-root {
          padding: 0 !important;
          .MuiInputBase-input {
            height: 44px !important;
            width: 100%;
            background-color: #242f53;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            box-shadow: 0px 1px 2px 0px #1018280d;
            border: none;
            background-image: url(${searchIcon});
            background-repeat: no-repeat;
            background-position-y: center;
            background-position-x: 14px;
            font-weight: 400 !important;
            font-size: 14px !important;
            color: #fff;
            outline: none;
            padding: 10px 14px 10px 40px !important;
            &::placeholder {
              color: inherit;
            }
          }
          .MuiOutlinedInput-notchedOutline {
          }
        }
      }
    }
  }
`;
