import { Box } from "@mui/material";
import styled from "styled-components";

export const ModalFormStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    > div {
        margin: 0;
        > div {
            margin: 0;
            display: flex;
            flex-direction: column;
            gap: 6px;
        }
    }
`

export const ModalFormButtonsBox = styled(Box)`
    display: flex;
    gap: 24px;
    padding-top: 12px;
    > button {
        width: 100%;
        height: 44px;
        display: inline-flex;
        align-items: center;
        border-radius: 10px;
        box-shadow: none !important;
        font-weight: 600;
        font-size: 16px;
    }
`