import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { TableComponentProps } from "./types";
import { TableComponentStyled } from "./styles";
import TablePagination from "./TablePagination";
import TableLoader from "./TableLoader";

const TableComponent: React.FC<TableComponentProps> = ({
  columns,
  data,
  isLoading,
  rowsPerPage,
  onPageChange,
  totalRecords,
  currentPage,
}) => {
  return (
    <TableComponentStyled>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && <TableLoader columns={columns} />}
          {data?.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((column) => {
                const value = row[column.id];
                return (
                  <TableCell key={column.id} align={column.align}>
                    {column.render ? column.render(value) : value}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
          {!data?.length && (
            <TableRow>
              <TableCell colSpan={columns.length} align="center">
                No records found!
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {onPageChange && Boolean(data?.length) && (
          <TableFooter>
            <TableRow>
              <TablePagination
                onPageChange={onPageChange}
                totalRecords={totalRecords || 0}
                currentPage={currentPage || 1}
                columns={columns}
                rowsPerPage={rowsPerPage}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableComponentStyled>
  );
};

export default TableComponent;
