import { useQuery } from "@apollo/client";
import apiClient, { ApiClient } from "../../../../lib/apiClient";
import useSession from "../../../../helpers/useSessionState";
import { Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useState } from "react";

import { useNavigate } from "react-router-dom";
import Layout from "../../elements/Layout";
import { DateTime } from "luxon";
import DataDrawer from "../../elements/DataDrawer";
import _ from 'lodash'
import SiteUpdate from "./SiteUpdate";
import SiteUpdateDrawer from "./SiteUpdateDrawer";

type State = {
    drawerOpen: boolean,
    site?: ApiClient.GetSites["sites"][0]
}

const SitesPartial = ()=>{
    const dateFormat = 'h:mm a L/dd/yy'
    const [state, setState] = useState<State>({
        drawerOpen: false 
    })

    const navigate = useNavigate()
    const { loading, error, data, refetch} = useQuery<ApiClient.GetSites>(apiClient.queries.GET_SITES);

    const [sessionState, setSession] = useSession()
    const setDrawer = (value: boolean)=>{
        setState({
            ...state,
            drawerOpen: value
        })
    }

    const mergeState = (value: Partial<State>)=>{
        setState({
            ...state,
            ...value
        })
    }
    
    const handleSelectSite = (site: ApiClient.GetSites["sites"][0])=>{
        setSession({
            ...Object(sessionState),
            currentSiteId: site.id
        })
        navigate("/")
    }

    const sites = data?.sites?.filter((site)=>
        site.practiceId == sessionState?.currentPracticeId
    )

    const orphanedSites = data?.sites?.filter((site)=>
        _.isNil(site.practiceId)
    )

    const handleUpdate = ()=>{
        refetch()
    }

    return <>
        {loading ? (
            <>
                LOADING...
            </>
        ):error?(
            <>{error.message}</>
        ):(
            <>
                Sites
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Created At</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Phone Number</TableCell>
                            <TableCell align="right">Integration</TableCell>
                            <TableCell align="right">Description</TableCell>
                            <TableCell align="right">Agent Updates</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {sites?.map((row, i: Number)=>(
                                <TableRow
                                    key={row.createdAt}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {DateTime.fromISO(row.createdAt).toFormat(dateFormat) }
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        <Link href={`/sites/${row.id}`} key={i.toString()}>
                                            {row.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {row.phoneNumber}
                                    </TableCell>
                                    <TableCell align="right">{row.integration}</TableCell>
                                    <TableCell align="right">{row.description}</TableCell>
                                    <TableCell align="right">
                                        <span>{row.currentAgentVersion}</span>
                                    </TableCell>
                                    <TableCell align="right">
                                        <span><SiteUpdateDrawer onUpdate={handleUpdate} site={row}/></span>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {sessionState?.isSuper && (
                    <>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        Orphaned Sites
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                    <TableCell>Created At</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Phone Number</TableCell>
                                    <TableCell align="right">Integration</TableCell>
                                    <TableCell align="right">Description</TableCell>
                                    <TableCell align="right">Agent Updates</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orphanedSites?.map((row, i: Number)=>(
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {DateTime.fromISO(row.createdAt).toFormat(dateFormat) }
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="right">
                                                <Link href={`/sites/${row.id}`} key={i.toString()}>
                                                    {row.name}
                                                </Link>
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="right">
                                                {row.phoneNumber}
                                            </TableCell>
                                            <TableCell align="right">{row.integration}</TableCell>
                                            <TableCell align="right">{row.description}</TableCell>
                                            <TableCell align="right">
                                                <span>{row.currentAgentVersion}</span>
                                            </TableCell>
                                            <TableCell align="right">
                                                <span><SiteUpdateDrawer onUpdate={handleUpdate} site={row}/></span>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
            </>
        )}
    </>
}

export default SitesPartial