import { Box, Button, IconButton, Typography } from "@mui/material";
import { TablePaginationStyled } from "./styles";
import { TablePaginationActionsProps, TablePaginationProps } from "./types";
import { ReactComponent as ArrowLeft } from "../../../../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../../../../assets/icons/arrow-right.svg";

const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    page: number
  ) => {
    onPageChange(event, page);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const totalPages = Math.ceil(count / rowsPerPage);

  const getPages = (): (number | string)[] => {
    if (totalPages <= 5) {
      return Array.from(Array(totalPages).keys());
    }

    if (page <= 2) {
      return [0, 1, 2, '...', totalPages - 1];
    }

    if (page >= totalPages - 3) {
      return [0, '...', totalPages - 3, totalPages - 2, totalPages - 1];
    }

    return [0, '...', page - 1, page, page + 1, '...', totalPages - 1];
  };

  const pages = getPages();

  return (
    <Box className="table-pagination-pages-controls">
      <IconButton
        className="prev-btn"
        disableFocusRipple
        disableRipple
        disableTouchRipple
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <ArrowLeft />
        <Typography>Previous</Typography>
      </IconButton>
      <Box className="pages">
        {pages.map((p, i) =>
          p === '...' ? (
            <Typography key={i} className="dots">
              ...
            </Typography>
          ) : (
            <Button
              key={i}
              onClick={(e) => handleButtonClick(e, p as number)}
              className={`${page === p ? 'active' : ''} single-page`}
            >
              {(p as number) + 1}
            </Button>
          )
        )}
      </Box>
      <IconButton
        className="next-btn"
        disableFocusRipple
        disableRipple
        disableTouchRipple
        onClick={handleNextButtonClick}
        disabled={page >= totalPages - 1}
        aria-label="next page"
      >
        <Typography>Next</Typography>
        <ArrowRight />
      </IconButton>
    </Box>
  );
};

const TablePagination: React.FC<TablePaginationProps> = ({
  columns,
  rowsPerPage,
  onPageChange,
  totalRecords,
  currentPage,
}) => {
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    onPageChange && onPageChange(newPage + 1);
  };

  return (
    <TablePaginationStyled
      rowsPerPageOptions={[]}
      colSpan={columns.length}
      count={totalRecords}
      rowsPerPage={rowsPerPage || 10}
      labelDisplayedRows={({ from, to, count }) => ""}
      page={currentPage - 1}
      onPageChange={handleChangePage}
      onRowsPerPageChange={() => {
        console.log("handleChangeRowsPerPage");
      }}
      ActionsComponent={TablePaginationActions}
    />
  );
};

export default TablePagination;
