import { generateActions } from "../app/components/partials/DataCenterListing";

export const transformData = (data: any, resource: string) => {
  return (
    data?.map((item: any) => {
      const commonTransformations = {
        ...item,
        isActive: item.isActive ? "Yes" : "No",
        actions: generateActions(item.id),
      };

      switch (resource) {
        case "patients":
          return {
            ...commonTransformations,
            isDeceased: item.isDeceased ? "Yes" : "No",
            updatedAt: new Date(item.updatedAt).toDateString(),
          };
        case "appointmentTypes":
        case "appointmentsStatuses":
        case "breeds":
        case "species":
        case "sexes":
        case "invoices":
        case "appointments":
          return {
            ...commonTransformations,
            isDeleted: item.isDeleted ? "Yes" : "No",
          };
        case "clients":
          return commonTransformations;
        default:
          return item;
      }
    }) || []
  );
};
