import { Controller } from "react-hook-form";
import {
  AdditionalFormFieldProps,
  FormField,
  SelectFieldProps,
} from "../common-types";
import SelectField from "./field";

const SelectFormField = (
  props: SelectFieldProps & AdditionalFormFieldProps & FormField
) => {
  const { name, control, defaultValue, options, multiple } = props;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <SelectField
          {...props}
          options={options}
          value={value ? value : multiple ? [] : ""}
          onChange={onChange}
          error={error}
          multiple={multiple}
        />
      )}
    />
  );
};

export default SelectFormField;
