import { MenuItem, Select, Typography } from "@mui/material";
import { SelectFieldStyled } from "./styles";
import { SelectFieldProps } from "../common-types";

const SelectField = (props: SelectFieldProps) => {
  const {
    name,
    label,
    value,
    error,
    disabled,
    options,
    multiple,
    className,
    placeholder,
    onChange,
    InputProps,
  } = props;
  return (
    <SelectFieldStyled className={`${className || ""} field-wrapper`}>
      {label && <Typography className="field-label">{label}</Typography>}
      <Select
        displayEmpty
        sx={{ width: "100%" }}
        value={value}
        id={name}
        onChange={onChange}
        error={!!error}
        multiple={multiple}
        disabled={disabled}
      >
        {Boolean(placeholder) && (
          <MenuItem disabled value="">
            {placeholder}
          </MenuItem>
        )}
        {options?.map(({ label, value }, index) => {
          return (
            <MenuItem value={value} key={index}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </SelectFieldStyled>
  );
};

export default SelectField;
